import React from 'react';

const DailyIcon = (): JSX.Element => {
  return (
    <img
      src='https://dailycrypto.net/assets/daily-CaqF7yVu.png'
      width='27'
      height='27'
      alt='Daily Icon'
    />
  );
};

export default DailyIcon;
