import React from 'react';
import { Footer as TFooter } from '@taraxa_project/taraxa-ui';
import { useFooterEffects } from './Footer.effects';

export const Footer = (): JSX.Element => {
  const { items } = useFooterEffects();

  return (
    <TFooter
      description='WE DESIGNED THE FUTURE FOR EVERYONE – JOIN US, THE FUTURE IS NOW.'
      links={[
        { label: 'Privacy Policy', link: 'https://dailycrypto.net/privacy' },
      ]}
      items={items}
    />
  );
};
